body {
  font-family: Muli !important;
  font-style: normal;
}

h1 {
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  @media (min-width: 769px) {
    font-size: 26px;
    line-height: 34px;
  }
  @media (min-width: 1024px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media (min-width: 1216px) {
    font-size: 30px;
    line-height: 38px;
  }
  @media (min-width: 1408px) {
    font-size: 34px;
    line-height: 40px;
  }
  &.bold {
    font-weight: bold;
  }
}

h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  @media (min-width: 769px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (min-width: 1216px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media (min-width: 1408px) {
    font-size: 20px;
    line-height: 23px;
  }
  &.bold {
    font-weight: bold;
  }
}

h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  // margin-bottom: 19px;
  &.bold {
    font-weight: bold;
  }
}

h4 {
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.0025em;
  // margin-bottom: 13px;
  &.bold {
    font-weight: bold;
  }
}

p,
a,
label {
  font-size: 12px;
  @media (min-width: 1408px) {
    font-size: 14px;
  }
  &.bold {
    font-weight: bold;
  }
  color: black;
}
