.section-container {
  padding: 2rem;
  height: 100%;
  overflow: auto;
}

.subtext-space {
  margin: 2rem 0;
  @media (max-width: 1408px) {
    margin: 1.5rem 0;
  }
}

.input-form {
  width: 16rem;
}

.input-form.filters-input {
  height: 2.5rem;
}

.reports-not-state {
  height: 70%;
}

.button-space {
  margin-left: 0.75rem;
}
