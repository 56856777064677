.h-100 {
  height: 100% !important;
}
.h-75 {
  height: 100% !important;
}
.h-50 {
  height: 100% !important;
}
.h-25 {
  height: 100% !important;
}
.w-100 {
  width: 100%;
}
.has-text-uex-red {
  color: #ff0000 !important;
}

.has-background-uex-red {
  background-color: #ff0000 !important;
}

.has-text-green {
  color: #247b04 !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.uex-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.uex-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

.uex-scrollbar::-webkit-scrollbar-thumb {
  background-color: #990202;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.p-0 {
  padding: 0px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mx-auto {
  margin: 0 auto;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.d-none {
  display: none !important;
}
.position-absolute {
  position: absolute;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.c-pointer {
  cursor: pointer;
}
